/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useDeliveryAddresses,
  useSystemSettings,
} from 'react-omnitech-api'
import { withAuthenticableNavigationBlock } from '../../../ui'
import { useAlert } from '../../../hook'
import AccountAddressesView from './account-addresses-view'

function AccountAddressesController() {
  // prepare hooks
  const alert = useAlert()
  const { t } = useTranslation()
  const { getSystemSetting } = useSystemSettings()
  const {
    deleteDeliveryAddresses,
    updateDeliveryAddresses,
    fetchDeliveryAddresses,
  } = useDeliveryAddresses()

  // internal state
  const [deliveryAddresses, setDeliveryAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState({})
  const [isEditBilling, setIsEditBilling] = useState(false)
  const [isEditDelivery, setIsEditDelivery] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pageReady, setPageReady] = useState(false)

  // local variable
  const seoTitle = t('screens.accountAddresses.seo.title')
  const isAllowedBillingAddress = /undefined|null|true/.test(getSystemSetting('account.billing_address.enable.ecom'))

  // useMemo
  const defaultDeliveryAddress = useMemo(() => {
    const address = _.find(deliveryAddresses, { isPrimary: true })
    return address || {}
  }, [deliveryAddresses])
  const defaultBillingAddress = useMemo(() => {
    const address = _.find(deliveryAddresses, { isBilling: true })
    return address || {}
  }, [deliveryAddresses])

  async function apiFetchDeliveryAddresses() {
    const options = {
      params: {
        includes: [
          'user_address_info',
        ].join(','),
      },
    }
    const { deliveryAddresses: data } = await fetchDeliveryAddresses(options)
    return data
  }

  async function apiUpdateDeliveryAddress(address) {
    return updateDeliveryAddresses({ address })
  }

  /**
   * handleCancel
   * close address book widget
   */
  function handleCancel() {
    setIsEditBilling(false)
    setIsEditDelivery(false)
  }

  /**
   * handleEditBilling
   * start edit billing address
   */
  function handleEditBilling() {
    setIsEditBilling(true)
    setSelectedAddress(defaultBillingAddress)
  }

  /**
   * handleEditDelivery
   * start edit delivery address
   */
  function handleEditDelivery() {
    setIsEditDelivery(true)
    setSelectedAddress(defaultDeliveryAddress)
  }

  /**
   * handleError
   * show error message via alert
   */
  function handleError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message)
  }

  /**
   * handleFetchDeliveryAddresses
   * fetch addresses from api
   */
  async function handleFetchDeliveryAddresses() {
    try {
      handleSetLoading(true)
      const data = await apiFetchDeliveryAddresses()
      setDeliveryAddresses(data)
      return data
    } catch (error) {
      handleError(error)
    } finally {
      handleSetLoading(false)
      setPageReady(true)
    }
  }

  /**
   * handleSaveBilling
   * save selected address as billing address
   */
  async function handleSaveBilling() {
    if (
      !_.isEmpty(selectedAddress)
      && (
        _.isEmpty(defaultBillingAddress)
        || defaultBillingAddress.id !== selectedAddress.id
      )
    ) {
      await handleUpdateDeliveryAddress({
        ...selectedAddress,
        isBilling: true,
      })
    }
    setIsEditBilling(false)
    setSelectedAddress({})
  }

  /**
   * handleSaveDelivery
   * save selected address as primary delivery address
   */
  async function handleSaveDelivery() {
    if (
      !_.isEmpty(selectedAddress)
      && (
        _.isEmpty(defaultDeliveryAddress)
        || defaultDeliveryAddress.id !== selectedAddress.id
      )
    ) {
      await handleUpdateDeliveryAddress({
        ...selectedAddress,
        isPrimary: true,
      })
    }
    setIsEditDelivery(false)
    setSelectedAddress({})
  }

  /**
   * handleSetLoading
   * update loading state
   * @param {*} value
   */
  function handleSetLoading(value) {
    setIsLoading(value)
  }

  /**
   * handleSelectAddress
   * save selected address for using in save
   * @param {*} address
   */
  function handleSelectAddress(address) {
    setSelectedAddress(address)
  }

  /**
   * handleUpdateDeliveryAddress
   * call api to update a delivery address
   * @param {*} address
   */
  async function handleUpdateDeliveryAddress(address) {
    try {
      handleSetLoading(true)
      // TODO: set shipment delivery address id
      // when success, show info
      await apiUpdateDeliveryAddress({
        ...address,
        country: address.countryCode,
      })
      const data = await apiFetchDeliveryAddresses()
      setDeliveryAddresses(data)
    } catch (error) {
      handleError(error)
    } finally {
      handleSetLoading(false)
    }
  }

  /**
   * handleDeleteAddress
   * call api to delete a address
   * @param {*} address
   */
  async function handleDeleteAddress(address) {
    try {
      handleSetLoading(true)
      await deleteDeliveryAddresses({
        addressId: _.get(address, 'id'),
      })
      // if selected address is deleted, reset it
      if (_.get(selectedAddress, 'id') === _.get(address, 'id')) {
        setSelectedAddress({})
      }
    } catch (error) {
      handleError(error)
    } finally {
      handleSetLoading(false)
    }
  }

  /**
   * when the page is loaded, fetch user's all delivery address
   */
  useEffect(() => {
    handleFetchDeliveryAddresses()

    return function CleanUp() {
      cancelRequest.cancelAll([
        'fetchDeliveryAddresses',
      ])
    }
  }, [])

  /**
   * when create new address and changed default delivery address
   * need to update the selected address to reflect the changes in address book
   */
  useEffect(() => {
    if (!isEditDelivery) return
    setSelectedAddress(defaultDeliveryAddress)
  }, [defaultDeliveryAddress])

  /**
   * when create new address and changed default billing address
   * need to update the selected address to reflect the changes in address book
   */
  useEffect(() => {
    if (!isEditBilling) return
    setSelectedAddress(defaultBillingAddress)
  }, [defaultBillingAddress])

  const viewProps = {
    defaultBillingAddress,
    defaultDeliveryAddress,
    deliveryAddresses,
    isEditBilling,
    isEditDelivery,
    isAllowedBillingAddress,
    isLoading,
    pageReady,
    selectedAddress,
    seoTitle,
    onCancel: handleCancel,
    onDeleteAddress: handleDeleteAddress,
    onEditBilling: handleEditBilling,
    onEditDelivery: handleEditDelivery,
    onFetchDeliveryAddresses: handleFetchDeliveryAddresses,
    onSaveBilling: handleSaveBilling,
    onSaveDelivery: handleSaveDelivery,
    onSelectAddress: handleSelectAddress,
    onUpdateDeliveryAddress: handleUpdateDeliveryAddress,
  }

  return (
    <AccountAddressesView {...viewProps} />
  )
}

export default withAuthenticableNavigationBlock(AccountAddressesController)
